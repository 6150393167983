main {
    max-width: 100%;
    #danke {
        min-height: calc(100vh - 95px);
        display: flex;
        background: $gradient3;
        align-items: center;
        .danke-wrap {
            max-width: 850px;
            width: 90%;
            padding: 30px;
            margin: 0 auto;
            h1 {
                color: #fff;
                font-size: 3rem;
                line-height: 1;
                margin-bottom: 30px; }
            h3 {
                color: #fff;
                font-size: 1.5rem;
                margin-bottom: 70px; } } }
    @media (max-width: 600px) {
        #danke {
            min-height: calc(100vh - 130px);
            .danke-wrap {
                h1 {
                    font-size: 3rem; }
                h3 {
                    font-size: 1rem; } } } }
    @media (max-width: 370px) {
        #danke {
            min-height: calc(100vh - 130px);
            .danke-wrap {
                h1 {
                    font-size: 2rem; }
                h3 {
                    font-size: .8rem; } } } } }

