.btn-outline {
    color: $rot;
    border: 2px solid $rot;
    width: 100%;
    display: block;
    height: 41px;
    border-radius: 100px;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 0.925rem;
    line-height: 41px;
    transition: .2s ease;
    @media (max-width: 400px) {
        font-size: 0.7rem; }
    &:hover {
        background: $rot;
        color: white; } }
.btn-outline-input {
    color: $rot;
    border: 2px solid $rot;
    width: 100%;
    display: block;
    height: 41px;
    border-radius: 100px;
    margin-bottom: 10px;
    font-size: 0.925rem;
    line-height: 41px;
    outline: none;
    text-align: center;
    &:hover {
        border: 2px solid $lila; } }

.btn {
    color: white;
    width: 100%;
    display: block;
    height: 41px;
    line-height: 41px;
    border-radius: 100px;
    cursor: pointer;
    margin: 20px 0 10px;
    font-size: 0.825rem;
    font-family: $font-family-headline;
    background: $rot;
    text-align: center;
    outline: none;
    border: none;
    &:hover {
        background: $gradient1;
        color: white; }
    &.inactive {
        // pointer-events: none
        &:hover {
            background: $gradient3; } } }
.btn-submit {
    color: white;
    width: 100%;
    display: block;
    line-height: 1;
    border-radius: 100px;
    cursor: pointer;
    margin: 20px 0 10px;
    font-size: 0.825rem;
    font-family: $font-family-headline;
    background: $rot;
    text-align: center;
    outline: none;
    border: none;
    padding: 13px 0;
    span {
        color: inherit;
        font-size: inherit;
        display: inline-block;
        max-width: 240px;
        &.large {
            font-size: 1rem;
            font-family: $font-family-headline; } }

    &:hover {
        background: $gradient1;
        color: white; }
    &.inactive {
        // pointer-events: none
        &:hover {
            background: $gradient3; } } }

.btn-large {
    width: 100%;
    color: white;
    display: inline-block;
    border-radius: 100px;
    cursor: pointer;
    padding: 10px 60px;
    margin: 20px 0 0 0;
    font-size: 1.2rem;
    font-family: $font-family-headline;
    background: $rot;
    text-align: center;
    outline: none;
    border: none;
    text-decoration: none;
    &:hover {
        background: $gradient1;
        color: white; } }


.button-white {
    color: $rot;
    background: #ffffff;
    padding: 20px 60px;
    border-radius: 50px;
    font-weight: 700;
    font-size: 1.2rem;
    text-decoration: none;
    transition: .2s ease;
    &:hover {
        opacity: 0.85; } }

.btn-red {
    color: white;
    width: 100%;
    display: block;
    height: 41px;
    line-height: 41px;
    cursor: pointer;
    border-radius: 100px;
    margin: 20px 0 10px;
    font-size: 0.825rem;
    font-family: $font-family-headline;
    background: $rot;
    text-align: center;
    outline: none;
    border: none;
    &:hover {
        background: $gradient1;
        color: white; }
    &.inactive {
        opacity: .5;
        cursor: not-allowed;
        &:hover {
            background: $rot; } } }


.amp-btn {
    color: white !important;
    font-size: 1rem !important;
    position: relative;
    display: inline-block;
    border-radius: 100px;
    cursor: pointer;
    padding: 10px 60px;
    margin: 20px 0 10px;
    font-size: 1.2rem;
    font-family: $font-family-headline;
    background: $rot;
    text-align: center;
    width: 100%;
    text-decoration: none;
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 100px;
        background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 0% 100%;
        opacity: 0;
        transition: background-size .3s, opacity .3s; }
    &:hover {
        &:before {
            opacity: 1;
            background-size: 100% 100%;
            color: #fff; } }
    span {
        color: white !important; }
    @media (max-width: 800px) {
        padding: 10px 30px; } }

.amp-btn.inactive {
    opacity: .8;
    cursor: not-allowed;
    &:hover {
        &:before {
            display: none; } } }
