#data {
    width: 90%;
    display: block;
    margin: 0 auto 5rem;
    max-width: 900px;
    button {
        border: none; }
    .output__row {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid grey;
        &:nth-child(even) {
            background: #efefef; }
        span {
            display: inline-block;
            padding-right: 1rem;
            padding: 0.5rem 0;
            &:first-child {
                width: 250px;
                padding-left: 0.5rem; }

            &:nth-child(2) {
                width: 50px; }

            &:nth-child(3) {
                width: 80px; }
            &:nth-child(4) {
                width: 170px; }
            &:nth-child(5) {
                width: 150px; }
            &:last-child {
                flex: 1 0 0; } } }
				text-align: center;
				padding: 0 20px;
				cursor: pointer;
				a {
					text-decoration: none;
					color: $rot; }
				// display: inline-block
    @media (max-width: 900px) {
        .output__row {
            display: block;
            padding: 1rem;
            span {
                display: block;
                width: 100%!important;
                padding: 0.1rem 0.5rem!important;
                margin: 0!important; } } } }
