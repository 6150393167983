header {
    background: url('../img/crs-header.png');
    background-size: cover;
    background-position: center;
    .mobile-headline {
        background: red; }
    .header-content {
        height: 40vh;
        min-height: 400px;
        max-width: 1280px;
        margin: 0 auto;
        .first-headline {} }
    @media (max-width: 1080px) {
        .header-content {
            height: 20vh;
            min-height: 420px;
            .first-headline {
                h1 {
                    font-size: 1.5rem; } } } }
    @media (max-width: 800px) {
        background-position: 62%;
        .header-content {
            height: 20vh;
            min-height: 250px; } }
    @media (max-width: 500px) {
        background-position: 62%;
        .header-content {
            height: 20vh;
            min-height: 200px; } } }


