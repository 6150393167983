main {
	#desktop-form {
		position: absolute;
		width: 88%; }

	.bullet-wrapper {
		background: $hellgrau;
		.main-bullets {
			max-width: 1280px;
			margin: 0 auto;
			padding: 50px 0;
			.col-2 {
				h4 {
					font-size: 2rem; } }
			ul {
				li {
					position: relative;
					font-size: 1.5rem;
					font-family: $font-family-headline;
					margin: 0 0 20px 40px;
					list-style: none;
					&:before {
						content: "";
						width: 30px;
						height: 25px;
						position: absolute;
						left: -40px;
						top: 3px;
						background: url(../img/i_check.svg) center no-repeat;
						background-size: contain; }
					&:last-child {
						margin: 0 0 0 40px; } } } } }
	.main-journal {
		max-width: 1280px;
		margin: 0 auto;
		padding: 50px 0px;
		.col-1 .col-1 {
			padding: 0px;
			p {
				margin-top: 20px; } }
		h4 {
			color: $rot;
			font-size: 2.5rem;
			span {
				color: $rot;
				font-size: 2.5rem;
				font-weight: 700; } }
		.softrow {
			align-items: center;
			.crs-logo {
				background: url(../img/crs-logo.png) center no-repeat; // Bild anpassen
				background-size: cover;
				max-width: 130px;
				max-height: 130px;
				width: 130px;
				height: 130px;
				min-height: 100px;
				min-width: 100px;
				@media (max-width: 800px) {
					max-height: 90px;
					max-width: 90px; } } }
		.mobile-crs-logo {
			@media (max-width: 570px) {
				flex-direction: column;
				align-items: baseline;
				.col-2 {
					padding-left: 0px !important; } } }
		h5 {
			font-size: 1.8rem;
			font-weight: 700;
			margin-bottom: 20px;
			@media (max-width: 500px) {
				font-size: 1.5rem; } }
		ul {
			li {
				font-size: 1.2rem;
				margin: 0 0 10px 20px; } }
		.main-zitate {
			position: relative;
			h5 {
				text-align: center; }
			p {
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					height: 50px;
					width: auto;
					margin-left: 10px; } }
			.sterne-softrow {
				align-items: stretch;
				.sterne-box {
					background: $hellgrau;
					margin: 10px;
					min-width: 200px;
					.sterne {
						align-items: center;
						justify-content: space-between;
						margin-bottom: 20px;
						img {
							width: 90px; }
						p {
							font-size: .6rem;
							margin-bottom: 0; } }
					p {
						font-size: .8rem;
						margin-bottom: 20px; }
					.name {
						font-size: .6rem;
						justify-content: flex-start;
						margin-bottom: 0;
						color: darken($grau, 40%); } } } } }
	.main-downloads {
		max-width: 1280px;
		margin: 0 auto;
		padding: 50px 0px;
		.mobile-downloads {
			.downloads-wrap {
				.col-1 {
					max-width: 50%; } }
			@media (max-width: 1000px) {
				.downloads-wrap {
					min-width: 100%;
					.col-1 {
						min-width: 50%;
						max-width: 50%; } }
				.crs-bild-wrap {
					display: none; } } }
		h4 {
			color: $rot;
			font-size: 2.5rem;
			span {
				color: $rot;
				font-size: 2.5rem;
				font-weight: 700; } }
		.softrow {
			align-items: flex-start;
			.crs-logo {
				background: url(../img/crs-logo.png) center no-repeat; // Bild anpassen
				background-size: cover;
				max-width: 130px;
				max-height: 130px;
				width: 130px;
				height: 130px;
				min-height: 100px !important;
				min-width: 100px; } }
		.mobile-crs-logo {
			@media (max-width: 570px) {
				flex-direction: column; } }

		h5 {
			font-size: 1.8rem;
			font-weight: 700;
			margin-bottom: 20px;
			@media (max-width: 500px) {
				font-size: 1.5rem; } }
		ul {
			li {
				font-size: 1.2rem;
				margin: 0 0 10px 20px; } }
		.main-zitate {
			position: relative;
			h5 {
				text-align: center; }
			p {
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					height: 50px;
					width: auto;
					margin-left: 10px; } }
			.sterne-softrow {
				align-items: stretch;
				.sterne-box {
					background: $hellgrau;
					margin: 10px;
					min-width: 200px;
					.sterne {
						align-items: center;
						justify-content: space-between;
						margin-bottom: 20px;
						img {
							width: 90px; }
						p {
							font-size: .6rem;
							margin-bottom: 0; } }
					p {
						font-size: .8rem;
						margin-bottom: 20px; }
					.name {
						font-size: .6rem;
						justify-content: flex-start;
						margin-bottom: 0;
						color: darken($grau, 40%); } } } }
		.crs-bild-wrap {
			height: 1000px;
			.crs-img {
				background: url('../img/crs-app.png');
				width: 100%;
				height: 100%; } }
		.downloads {
			.col-1 {
				img {
					width: 100%;
					border: 1px solid $hellgrau; } } } } }

.main-zitate {
	@media (max-width: 800px) {
		display: none; } }
