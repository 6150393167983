
.cookie-banner-close button,
.cookie-banner-details button {
    padding: 0;
    font-size: 12px;
    background: transparent;
    border: none;
    border: 2px solid #ffffff;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 22px;
    width: 100%;
    @media screen and (min-width: 800px) {
        width: auto; } }


.cookie-banner-content p,
.cookie-content {
    font-size: 12px;
    margin-bottom: 5px;
    color: #ffffff;
    width: 100%;
    float: left;
    max-width: 70%;
    @media screen and (min-width: 1200px) {
        max-width: 80%;
        margin-right: 95px;
        margin-top: 5px; } }

.cookie-content {
    @media (max-width: 600px) {
        max-width: 100%;
        margin-bottom: 20px; } }
.cookie-content {
    a {
        color: white; } }
.cookie-banner-wrapper {
    max-width: 1200px; }


.cookie-banner-overContent .cookie-banner {
    z-index: 88888; }


.cookie-banner-buttons {
    display: flex; }


.cookie-banner-content {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 600px) {
        flex-direction: column; } }


.cookie-banner-content div a {
    font-size: 12px;
    font-weight: bold;
    display: inline-block; }


.cookie-banner-close {
    font-size: 12px;
    text-decoration: underline;
    color: #000;
    cursor: pointer; }

.cookie-banner-details {
    line-height: 0!important; }


.cookie-banner {
    display: none;
    z-index: 9999;
    background: #444444;
    border-top: 1px solid #ffffff;
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 20px 0; }


.cookie-banner-wrapper {
    padding: 0 30px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }

