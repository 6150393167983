* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html, body {
  height: 100%;
  font-size: 100%;
  line-height: 1.4;
  transition: all 1s ease; }

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden; }

main {
	max-width: 1280px;
	margin: 0 auto; }



@font-face {
	font-family: 'value';
	src: url('../fonts/value/Value-Regular.woff2') format('woff2');
	src: url('../fonts/value/Value-Regular.woff') format('woff');
	src: url('../fonts/value/Value-Regular.otf') format('otf');
	src: url('../fonts/value/Value-Regular.ttf') format('ttf');
	font-weight: normal;
	font-style: normal; }


@font-face {
	font-family: 'valueBold';
	src: url('../fonts/value/Value-Bold.woff2') format('woff2');
	src: url('../fonts/value/Value-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal; }


@font-face {
	font-family: 'univers';
	src: url('../fonts/univers/UniversLTStd.woff2') format('woff2');
	src: url('../fonts/univers/UniversLTStd.woff') format('woff');
	font-weight: normal;
	font-style: normal; }


@font-face {
	font-family: 'univers';
	src: url('../fonts/univers/UniversLTStd-Bold.woff2') format('woff2');
	src: url('../fonts/univers/UniversLTStd-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal; }

@font-face {
    font-family: 'chalk';
    src: url('../fonts/chalk/VerySimpleChalk.woff2') format('woff2'), url('../fonts/chalk/VerySimpleChalk.woff') format('woff');
    font-weight: normal;
    font-style: normal; }



*, p, strong, ul, li, a {
	font-family: $body-font-family;
	color: #707070;
	font-size: 14px; }

h1, h2, h3, h4, h6 {
	font-family: $font-family-headline; }

h1 {
	font-size: 2.5rem;
	color: $rot;
	margin: 30px 0 30px 0; }


h6 {
	text-align: center;
	margin-bottom: 10px; }



hr {
    height: 6px !important;
    width: 100% !important;
    background: $gradient3 !important;
    border: none !important; }


.softrow {
	display: flex;
	flex-wrap: wrap; }

.col-1 {
	flex: 1 0 0;
	padding: 3%; }

.col-2 {
	flex: 2 0 0;
	padding: 3%; }
