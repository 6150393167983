form {
    position: relative;
    border: 5px solid;
    border-color: $rot;
    z-index: 999;
    background: #fff;
    min-height: 680px;
    box-shadow: $shadow;
    @media (max-width: 500px) {
        height: 750px; }
    @media (max-width: 330px) {
        height: 800px; }
    p {
        font-family: value,Helvetica,Arial,sans-serif; }
    strong {
        font-family: valueBold,Helvetica,Arial,sans-serif; }
    .formHeader {
        background: $rot;
        padding: 15px 15px;
        text-align: center;
        position: relative;
        &:after {
            bottom: 0;
            left: 50%;
            position: absolute;
            content: "";
            width: 50px;
            height: 50px;
            background-image: $rot;
            background-size: 3000px;
            background-position: center center;
            transform: rotate(45deg) translateY(49%) translateX(-20%);
            z-index: -1; }
        h2 {
            color: white;
            font-size: 1.8rem;
            line-height: 1.2;
            margin-bottom: 15px;
            span {
                font-family: $font-family-headline-light!important;
                font-size: inherit;
                color: white;
                line-height: inherit; } }
        h4 {
            color: white !important;
            font-size: 0.9rem !important;
            span {
                color: inherit; } } }
    .formContent {
        padding: 40px 20px 20px 20px;
        max-width: 380px;
        margin: 0 auto;
        // background: #fff
        height: 70%;
        @media (max-width: 500px) {
            height: 68%; }

        .question {

            display: none;
            text-align: center;
            &.active {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%; }
            input[type="radio"] {
                display: none;
                &:checked~label {
                    background: $gradient3;
                    color: white;
                    border: 2px solid rgba(255,255,255,.0);
                    border-left-color: $rot;
                    border-right-color: $lila; } }
            strong {
                font-size: 1.2rem;
                line-height: 1.3; }
            p {
                font-size: 0.925rem; } } }
    .inputAnrede {
        margin-bottom: 10px;
        #formAnrede {
            font-weight: 700; }
        input {
            margin-left: 15px; } }
    .inputText {
        margin-top: 20px;
        font-family: $body-font-family;
        font-size: .8rem !important;
        text-align: left;
        font-weight: 700; } }

.alert {
    font-weight: 700;
    color: $rot; }

main {
    .main-journal {
        @media (max-width: 800px) {
            padding: 0 0 50px 0 !important;
            .order1 {
                order: 1; }
            .order2 {
                order: 2; }
            .main-zitate.order1 {
                display: flex;
                min-width: 100%;
                padding: 0 0 3% 0;
                #desktop-form {
                    position: relative;
                    width: 100%; } } } } }
