nav {
    background: white;
    border-bottom: 2px solid #D9D9D9;
    #nav-wrapper {
        padding: 20px;
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .nav-logo {
            align-self: center;
            img {
                //height: 50px
                display: block;
                max-width: 100%; } }
        .nav-right {
            display: flex;
            .nav-kunden {
                margin-right: 40px;
                img {
                    height: 80px;
                    display: block; } }
            .nav-call {
                color: white;
                align-self: center;
                a {
                    display: flex;
                    text-decoration: none;
                    align-items: baseline;
                    h3 {
                        font-size: 2rem;
                        line-height: 1;
                        margin-bottom: 10px; }
                    img {
                        height: 25px;
                        margin-right: 15px; } }
                p {
                    font-size: .8rem; }
                .open-mobile {
                    display: none; } } } }
    @media (max-width: 680px) {
        #nav-wrapper {
            flex-direction: column;
            justify-content: center;
            padding: 0;
            .nav-logo {
                img {
                    // height: 40px
                    margin: 20px 0; } }
            .nav-right {
                justify-content: center;
                padding: 15px 0;
                background: $rot;
                .nav-kunden {
                    display: none; }
                .nav-call {
                    display: flex;
                    width: 100%;
                    padding: 0 20px;
                    justify-content: space-between;
                    align-items: center;
                    a {
                        h3 {
                            margin-bottom: 0px;
                            font-size: 1.3rem; }
                        img {
                            height: 16px;
                            margin-right: 10px; } }
                    @media (max-width: 370px) {
                        a {
                            img {
                                display: none; } }
                        .open-mobile {
                            font-size: .5rem !important; } }
                    p {
                        display: none; }
                    .open-mobile {
                        display: block;
                        font-size: .6rem; } } } } } }
