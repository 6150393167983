
footer {
    background: #1A1A1A;
    .footer-wrapper {
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 30px 20px;
        align-items: center;
        color: white;
        .mobile-open {
            display: none; }
        p {
            font-size: .8rem; }
        .legal, .mobile-open {
            a {
                text-decoration: none;
                margin-left: 10px;
                &:hover {
                    text-decoration: underline; } } }
        .nav-call {
            color: white;
            align-self: center;
            a {
                display: flex;
                text-decoration: none;
                align-items: baseline;
                h3 {
                    font-size: 2rem;
                    line-height: 1;
                    margin-bottom: 10px; }
                img {
                    height: 25px;
                    margin-right: 15px; }
                @media (max-width: 370px) {
                    h3 {
                        font-size: 1.8rem; }
                    img {
                        height: 20px; } } }
            p {
                font-size: .8rem; } }
        @media (max-width: 760px) {
            flex-direction: column;
            .mobile-open {
                display: block; }
            .data-margin {
                margin-top: 8px; }
            p {
                display: none; } } } }



.footer-cookie {
    margin-bottom: 84px;
    @media (max-width: 1148px) {
        margin-bottom: 94px; }
    @media (max-width: 783px) {
        margin-bottom: 110px; }
    @media (max-width: 608px) {
        margin-bottom: 126px; }
    @media (max-width: 600px) {
        margin-bottom: 152px; }
    @media (max-width: 565px) {
        margin-bottom: 168px; }
    @media (max-width: 375px) {
        margin-bottom: 200px; } }
